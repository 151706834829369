import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Icon, Avatar, Stack, Divider } from "@mui/material";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import "./CalendarSlider.css";

const timePeriods = [
  { label: "00:00 - 06:00", icon: <NightlightRoundIcon />, color: "#2C3E50" },
  { label: "06:00 - 12:00", icon: <WbSunnyIcon />, color: "#F1C40F" },
  { label: "12:00 - 18:00", icon: <BrightnessHighIcon />, color: "#3498DB" },
  { label: "18:00 - 24:00", icon: <LocationCityIcon />, color: "#E74C3C" },
];

const CalendarSlider = ({ today, user, gpuData, data, timeLabels }) => {
  const [selectedAreas, setSelectedAreas] = useState([]); // 선택된 영역들
  const [scheduleData, setScheduleData] = useState([]); // 스케줄 데이터
  const [showPopup, setShowPopup] = useState(false); // 팝업 표시 상태
  const [showCancelPopup, setShowCanclePopup] = useState(false); // 팝업 표시 상태
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const toCancelSchedule = useRef(null);
  const isDragging = useRef(false);
  const rowHeaderRef = useRef(null); // Row Header Ref
  const bodyRef = useRef(null); // Body Ref
  const initLoading = useRef(false);
  const userImageCache = useRef({});

  const setUserProfileImage = async (user_id) => {
    try {
      const response = await axios.get(
        `https://api.kc-softmax.com/get_user_profile_image?user_id=${user_id}`
      );
      userImageCache.current[user_id] = response.data;
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSchedules = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://api.kc-softmax.com/get_schedules"
      );
      for (let i = 0; i < response.data.length; i++) {
        const user_id = response.data[i].user_id;
        if (!userImageCache.current[user_id]) {
          await setUserProfileImage(user_id);
        }
      }
      setScheduleData(response.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelSchedule = async (scheduleId) => {
    try {
      await axios.post("https://api.kc-softmax.com/cancel_schedule", {
        user_id: user.id,
        schedule_id: scheduleId,
      });
      // refresh schedules
      getSchedules();
      setShowCanclePopup(false); // 팝업 닫기
    } catch (err) {
      console.log(err.message);
      return;
    }
  };

  const reserveSchedule = async (gpuId, time, timeIndex) => {
    try {
      await axios.post("https://api.kc-softmax.com/reserve_schedule", {
        gpu_id: gpuId,
        user_id: user.id,
        select_date: time,
        time_index: timeIndex,
      });
      getSchedules();
    } catch (err) {
      console.log(err.message);
      return;
    }
  };

  const getGpuName = (rowIndex) => {
    const originName = gpuData[rowIndex].name;
    // REMOVE NVIDIA, GeForce
    const name = originName.replace("NVIDIA", "").replace("GeForce", "");
    return name;
  };

  const getServerName = (rowIndex) => {
    return gpuData[rowIndex].server;
  };

  const getMemorySize = (rowIndex) => {
    const memory = Math.ceil(gpuData[rowIndex].memory_total / 1024);
    return `${memory}GB`;
  };

  const checkSelected = (row, col, section) => {
    return selectedAreas.some(
      (area) => area.row === row && area.col === col && area.section === section
    );
  };

  const checkReserved = (row, col, section) => {
    const reservedSchedule = scheduleData.find(
      (schedule) =>
        schedule.gpu_id === gpuData[row].id &&
        schedule.select_date === timeLabels[col] &&
        schedule.time_index === section
    );
    return reservedSchedule;
  };

  const handleMouseDown = (row, col, section) => {
    if (timeLabels[col] < today) return; // 오늘 이전의 시간은 선택 불가

    const reservedSchedule = checkReserved(row, col, section);
    // 사용자의 예약인지 확인
    if (reservedSchedule) {
      // 사용자의 예약이 아닌 경우 작업 중단
      if (reservedSchedule.user_id !== user.id) return;
      // 사용자의 경우 취소 팝업 표시
      toCancelSchedule.current = reservedSchedule;
      setShowCanclePopup(true); // 팝업 닫기
    } else {
      isDragging.current = true;
      setSelectedAreas([{ row, col, section }]);
    }
  };

  const handleMouseMove = (row, col, section) => {
    if (timeLabels[col] < today) return; // 오늘 이전의 시간은 선택 불가
    if (!isDragging.current) return;

    const reservedSchedule = checkReserved(row, col, section);
    if (reservedSchedule) return; // 점유된 경우 작업 중단

    setSelectedAreas((prev) => {
      const alreadyExists = prev.some(
        (area) =>
          area.row === row && area.col === col && area.section === section
      );
      if (!alreadyExists) {
        return [...prev, { row, col, section }];
      }
      return prev;
    });
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    if (selectedAreas.length > 0) {
      setShowPopup(true); // 팝업 표시
    }
  };

  const handlePopupConfirm = () => {
    for (let i = 0; i < selectedAreas.length; i++) {
      const row = selectedAreas[i].row;
      const col = selectedAreas[i].col;
      const gpu = gpuData[row];
      const time = timeLabels[col];
      const timeIndex = selectedAreas[i].section;
      reserveSchedule(gpu.id, time, timeIndex);
    }

    setSelectedAreas([]); // 선택된 영역 초기화
    setShowPopup(false); // 팝업 닫기
  };

  const handlePopupCancel = () => {
    setSelectedAreas([]); // 선택된 영역 초기화
    setShowPopup(false); // 팝업 닫기
  };

  const handleCanclePopupConfirm = async () => {
    await cancelSchedule(toCancelSchedule.current.id);
    toCancelSchedule.current = null;
  };

  const handleCanclePopupCancel = () => {
    toCancelSchedule.current = null;
    setShowCanclePopup(false); // 팝업 닫기
  };

  // 동기화된 스크롤 핸들러
  const syncScroll = () => {
    // console.log("syncScroll");
    if (rowHeaderRef.current && bodyRef.current) {
      rowHeaderRef.current.scrollTop = bodyRef.current.scrollTop;
    }
  };

  useEffect(() => {
    if (initLoading.current) return;
    initLoading.current = true;
    setUserProfileImage(user.id);
    getSchedules();
    if (bodyRef.current) {
      bodyRef.current.scrollLeft = 384 + 72;
    }
  }, []);

  return (
    <div style={{ display: "flex", overflowY: "hidden" }}>
      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>로딩 중...</p>
        </div>
      )}
      {/* 예약 팝업 */}
      <React.Fragment>
        <Dialog
          open={showPopup}
          onClose={handlePopupCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"예약하시겠습니까?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupConfirm} autoFocus>
              확인
            </Button>
            <Button onClick={handlePopupCancel}>취소</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      {/* 취소 팝업 */}
      <React.Fragment>
        <Dialog
          open={showCancelPopup}
          onClose={handleCanclePopupCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"예약을 취소하시겠습니까?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCanclePopupConfirm} autoFocus>
              확인
            </Button>
            <Button onClick={handleCanclePopupCancel}>취소</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      {/* 고정된 행 헤더 */}
      <Box
        sx={{
          position: "fixed", // 화면에 고정
          padding: 1,
          height: "100vh", // 전체 화면 높이
          width: 96, // 고정된 너비
          zIndex: 3000, // 화면 상위에 표시
          backgroundColor: "white",
          // color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "2px 0px 6px rgba(0, 0, 0, 0.1)", // 그림자 효과
        }}
      >
        <div
          className="calendar-row-header"
          ref={rowHeaderRef}
          onScroll={syncScroll} // 헤더 스크롤 이벤트 핸들러
        >
          <Stack divider={<Divider orientation="horizontal" flexItem />}>
            <Box height="102px">
              <Stack paddingTop={1} alignItems={"center"}>
                <Avatar src={userImageCache.current[user.id]} />
                <Typography variant="body1" color="black">
                  {user.name}
                </Typography>
                <Typography variant="body1" color="black" fontSize={10}>
                  {user.serverUserName}
                </Typography>
              </Stack>
            </Box>
            {data.map((row, rowIndex) => (
              <Box height={"72px"} alignContent={"center"}>
                <Stack>
                  <Typography variant="body" color="black" fontWeight={"800"}>
                    {getServerName(rowIndex)}
                  </Typography>
                  <Typography variant="caption" fontSize={12}>
                    {getGpuName(rowIndex)}
                  </Typography>
                  <Typography variant="caption" fontSize={10}>
                    {getMemorySize(rowIndex)}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
        </div>
      </Box>
      {/* Body */}
      <div
        className="calendar-body"
        ref={bodyRef}
        onScroll={syncScroll} // 본문 스크롤 이벤트 핸들러
        onMouseUp={handleMouseUp} // 마우스 업 이벤트 핸들
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={3}
          paddingBottom={"24px"}
        >
          {timeLabels.map((timeLabel, index) => (
            <Stack>
              <Box height={72} minWidth={192} alignContent={"center"}>
                <Typography textAlign={"center"} variant="body1">
                  {timeLabel}
                  {today === timeLabel && " (Today) "}
                </Typography>
              </Box>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption" fontSize={9}>
                  {"0:00 am"}
                </Typography>
                <Typography variant="caption" fontSize={9}>
                  {"23:59 pm"}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        {data.map((row, rowIndex) => (
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={3}
            paddingTop={"12px"}
            paddingBottom={"12px"}
          >
            {row.map((_, colIndex) => (
              <Box height={48} width={192}>
                <Stack direction="row" spacing={1}>
                  {/* 4개의 섹션 */}
                  {[0, 1, 2, 3].map((section) => {
                    const selected = checkSelected(rowIndex, colIndex, section);
                    const reservedSchedule = checkReserved(
                      rowIndex,
                      colIndex,
                      section
                    );
                    return (
                      <Box
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        key={section} // 각 섹션에 고유 key 추가
                        sx={{
                          justifyContent: "center",
                          color: "white",
                          cursor: "pointer",
                          borderRadius: 2,
                          boxShadow: 3,
                          gap: 1,
                          width: "48px",
                          height: "48px",
                          marginBottom: "4px",
                          transition: "background-color 0.3s", // 부드러운 전환 효과
                          "&:hover": {
                            backgroundColor: reservedSchedule
                              ? "#512da8"
                              : "#a5d6a7", // 호버 시 색상
                          },
                          backgroundColor: reservedSchedule
                            ? reservedSchedule.deprecated
                              ? "#9E9E9E"
                              : timePeriods[section].color
                            : selected
                            ? "#388e3c"
                            : "#e8f5e9",
                        }}
                        onMouseDown={() =>
                          handleMouseDown(rowIndex, colIndex, section)
                        }
                        onMouseMove={() =>
                          handleMouseMove(rowIndex, colIndex, section)
                        }
                      >
                        {reservedSchedule &&
                        userImageCache.current[reservedSchedule.user_id] ? (
                          <Avatar
                            sx={{ width: 36, height: 36 }}
                            alt={reservedSchedule.user_name}
                            src={
                              userImageCache.current[reservedSchedule.user_id]
                            }
                          />
                        ) : (
                          <Icon>{timePeriods[section].icon}</Icon>
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            ))}
          </Stack>
        ))}
      </div>
    </div>
  );
};

export default CalendarSlider;
