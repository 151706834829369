import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./App.css";
import CalendarSlider from "./CalendarSlider";

function App() {
  const [gpuData, setGpuData] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [timeLabels, setTimeLabels] = useState([]);
  const [today, setToday] = useState(null);
  const [user, setUser] = useState(null);

  const isLoading = useRef(false);
  const isLoadingFinished = useRef(false);
  const cols = 13;
  var rows = 0;

  // 데이터를 로드하는 함수
  const loadData = async () => {
    try {
      const response = await axios.get(
        "https://api.kc-softmax.com/get_base_time"
      );
      // time range
      // start - baseTime - 5 days
      // end - baseTime + 10 days
      const baseTime = new Date(response.data);
      setToday(
        baseTime.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
      );
      const timeLabels = Array.from({ length: 13 }, (_, index) => {
        const date = new Date(baseTime);
        date.setDate(date.getDate() + index - 2);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      });
      setTimeLabels(timeLabels);
    } catch (err) {
      console.log(err.message);
      return;
    }

    try {
      const response = await axios.get("https://api.kc-softmax.com/get_gpus");
      setGpuData(response.data);
      rows = response.data.length;
    } catch (err) {
      console.log(err.message);
      return;
    }

    // 랜덤 데이터를 생성하거나 API 호출 결과를 사용할 수 있음
    const newData = Array.from({ length: rows }, (_, rowIndex) =>
      Array.from({ length: cols }, (_, colIndex) => ({
        row: rowIndex,
        col: colIndex,
        deprecated: timeLabels[colIndex] < today,
      }))
    );
    setCalendarData(newData);
    isLoadingFinished.current = true;
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    if (isLoading.current) return;
    isLoading.current = true;
    const query = new URLSearchParams(window.location.search);
    setUser({
      id: query.get("user_id"),
      name: query.get("user_name"),
      serverUserName: query.get("server_user_name"),
    });
    loadData();
  }, []);
  return (
    <div className="app">
      <div>
        {!isLoadingFinished.current ? null : (
          <CalendarSlider
            today={today}
            user={user}
            gpuData={gpuData}
            timeLabels={timeLabels}
            data={calendarData}
          />
        )}
      </div>
    </div>
  );
}

export default App;
